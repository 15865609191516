<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="true")
		.modal-header
			.modal-title Refresh Subsources
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				form.form.form-inline.ov-filters(@submit.prevent="search()")
					.form-row-main
						div
							.form-group
								label Search Subsources
								b-input-group
									b-form-textarea(rows="5", placeholder="paste rejected subsources here", v-model="filters.rejectedSubsourcesSearch")

							.form-group.no-label
								button.btn.btn-primary(type="submit", :disabled="!searchCount") Search ({{ searchCount }})

					.form-row-end
						.form-group
							label &nbsp;
							button.btn.btn-primary(type="button", @click="refreshSelectedSubsources()", :disabled="!totalSelected") Refresh ({{totalSelected}})

				p.clearfix &nbsp;
				.ov-table-wrapper
					table.table.table-bordered.table-offer-list
						thead
							tr
								th.text-right
									span
										a(href="javascript:void(0);", @click="selectAll()") All
										=' | '
									span
										a(href="javascript:void(0);", @click="clearBucket()") Clear

						tbody(v-if="records.length === 0")
							tr
								td No matching records were found

						tbody(v-for="offer in records")
							tr.offer-row(:class="{'row-selected': offer.selectedCount === offer.subsources.length}")
								td
									.d-flex.justify-content-between
										.offer
											a.btn.btn-link(@click="toggleVisible(offer)")
												i.la(:class="{'la-caret-right': !offer.showSubsources, 'la-caret-down': offer.showSubsources}")
											| {{ offer.advertiser_name }} - {{ offer.name }} [{{ offer.id }}]
										.offer-selection
											span.select-count.mr-2(:class="{'text-muted': +offer.selectedCount === 0}") ({{offer.selectedCount}} / {{offer.subsources.length}})
											span
												a(href="javascript:void(0);", @click="selectOfferSources(offer)")
													i.la.la-check-square-o
												=' | '
											span
												a(href="javascript:void(0);", @click="clearOfferSources(offer)")
													i.la.la-square-o
							b-collapse(:id="'collapse-'+offer.id", v-model="offer.showSubsources")
								table.table.table-bordered.table-offer-list(style="margin-bottom:0")
									tbody
										tr(v-for="r in offer.subsources", v-bind:key="r.subsource", :class="{'row-selected': r._selected}")
											td
												.ml-5 {{ r.subsource }}
											td.col-select(width="70px")
												a(href="javascript:void(0)", @click="toggleSubsource(r)")
													i.la(:class="{'la-check-square':r._selected, 'la-stop':!r._selected}")

</template>
<style lang="scss">
.offer-row {
  font-weight: bold;
  //background: #f2f2f2 !important;
}
</style>
<script>
import Vue from 'vue';

export default {
  name: 'RefreshSubsourcesModal',
  props: ['offer_ids'],
  data() {
    return {
      records: [],
      busy: false,
      filters: {
        rejectedSubsourcesSearch: '',
      },
      totalSelected: 0,
    };
  },
  async mounted() {
    this.loadData();
  },
  computed: {
    searchCount() {
      return this.filters.rejectedSubsourcesSearch.split('\n').filter((s) => !!s).length;
    },
  },
  methods: {
    updateCount() {
      this.totalSelected = 0;
      this.records.forEach((o) => {
        o.selectedCount = 0;
        o.subsources.forEach((s) => {
          if (s._selected) o.selectedCount++;
        });
        this.totalSelected += o.selectedCount;
      });
    },
    clearBucket() {
      this.records.forEach((offer) => {
        this.clearOfferSources(offer);
      });
      this.updateCount();
    },
    selectAll() {
      this.records.forEach((offer) => {
        this.selectOfferSources(offer);
      });
      this.updateCount();
    },
    clearOfferSources(offer) {
      offer.subsources.forEach((s) => Vue.set(s, '_selected', false));
      this.updateCount();
    },
    selectOfferSources(offer) {
      offer.subsources.forEach((s) => Vue.set(s, '_selected', true));
      this.updateCount();
    },
    toggleSubsource(r) {
      Vue.set(r, '_selected', !r._selected);
      this.updateCount();
    },
    toggleVisible(offer) {
      offer.showSubsources = !offer.showSubsources;
    },

    search() {
      let rejectedSubHash = this.filters.rejectedSubsourcesSearch.split('\n').reduce((acc, s) => {
        acc[s] = true;
        return acc;
      }, {});
      this.records.forEach((r) => {
        r.subsources.forEach((s) => {
          s._selected = !!rejectedSubHash[s.subsource];
        });
      });
      this.updateCount();
      if (this.searchCount > 0 && this.totalSelected === 0) {
        this.$ovNotify.error('No matching subsources found in this list');
      }
    },

    async loadData() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let params = {
        offer_ids: this.offer_ids.join(','),
      };
      try {
        let resp = await this.$ovReq.get('offer/getSubsourceInfo', { params });
        this.records = resp.records;
        this.search();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async refreshSelectedSubsources() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      try {
        let selectedSubs = this.records.map((o) => ({
          id: o.id,
          subsources: o.subsources.filter((r) => r._selected),
        }));
        await this.$ovReq.post('offer/replaceSpecificSubsources', { subsources: selectedSubs });
        this.$ovNotify.success('Subsources were removed and replaced');
        this.busy = false;
        await this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
