<template lang="pug">
	div.default-modal
		.modal-header
			.modal-title Whitelist, Blacklist
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				.row
					.col-sm-6
						strong White List
							ul
								li(v-for="r in offer.white_list") {{ r }}
					.col-sm-6
						strong Black List
						ul
							li(v-for="r in offer.black_list") {{ r }}
</template>
<script>
export default {
  name: 'OfferWLBLModal',
  props: ['offer'],
};
</script>
