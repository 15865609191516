<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")
    .widget(v-if="USER")
      .widget-header.has-button
        h1.title Offers
        i.la.la-2x.la-filter.d-flex.align-items-center.mr-2.mobile(@click="areFiltersOpened = !areFiltersOpened")
        .header-button
          .input-group.btn-select
            .input-group-prepend
              b-dropdown(variant="primary", dropleft, text="Add", v-b-tooltip.hover, title="New Manual Campaign Set-up")
                b-dropdown-item(v-for="o in advertiserOptionsLive", @click="addOffer(o.v)") {{ o.t }}

      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'offersList'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            .main-filters-row
              .main-filters
                .form-group
                  label Search
                  input.form-control(type="text", placeholder="Keyword...", v-model="filters.keyword")
                .form-group.fg-advertiser
                  select-advertiser(v-model="filters.advertiser_id")
                .form-group
                  label Status
                  select.form-control(v-model="filters.status")
                    option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
                .form-group
                  label Account Manager
                  multiselect(:multiple="true", :options="accountManagerOptions", v-model="filters.am_id",
                    track-by="v", label="t", deselect-label="", select-label="")
                .form-group
                  label Validation
                  select.form-control(v-model="filters.validation_status")
                    option(v-for="o in validationStatusOptions", :value="o.v") {{ o.t }}
                .form-group
                  label Model
                  select.form-control(v-model="filters.payout_model")
                    option(value="all") All
                    option(v-for="o in payoutModelOptions", :value="o.v") {{ o.v }}
                .form-group
                  label OS
                  select.form-control(v-model="filters.platform")
                    option(v-for="o in platformOptions", :value="o.v") {{ o.t }}
                .form-group
                  label MMP Account
                  select.form-control(v-model="filters.mmp_account_id")
                    option(value="") -
                    option(v-for="o in mmpAccountOptions", :value="o.v") {{ o.t }}
                .form-group
                  label MMP
                  select.form-control(v-model="filters.mmp")
                    option(value="all") All
                    option(value="required") Required
                    option(value="none") None
                    option(v-for="o in mmpOptions", :value="o.v") {{ o.v }}
                .form-group
                  label Type
                  select.form-control(v-model="filters.offer_type")
                    option(value="all") All
                    option(value="normal") Normal
                    option(value="complex") Complex

              .form-group.no-label.desktop
                b-button-group.submit-filters-button
                  b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                    b-dropdown-item(@click="loadData(1)")
                      i.la.la-download.mr-1
                      | Export
                    b-dropdown-item(href="/app/offers/default-settings")
                      i.la.la-cog.mr-1
                      | Default Settings

            div.advanced-filters(v-show="showAdvancedFilters")
              div
                .form-group
                  label Bulk Search
                  textarea.form-control(placeholder="ID List...", v-model="filters.offer_ids", rows="1")
                .form-group.fg-advertiser
                  label Exclude Advertiser(s)
                  multiselect(:multiple="true", :options="advertiserOptions", v-model="filters.exclude_advertiser_id",
                    track-by="v", label="t", deselect-label="", select-label="")
                .form-group
                  label Group / Rank
                  select.form-control(v-model="filters.group_name")
                    option(:value="''") none
                    option(:value="'A'") A
                    option(:value="'B'") B
                    option(:value="'C'") C
                .form-group
                  label ComplexID
                  input.form-control(type="text", placeholder="", v-model="filters.complex_id")
                .form-group
                  label # redirects
                  input.form-control(style="width: 80px", type="text", placeholder="Max Redirect", v-model.number="filters.max_redirects")
                .form-group
                  label Countries
                  input.form-control(type="text", v-model="filters.countries", placeholder="US, GB...")
                .form-group
                  label Bundle(s)
                  multiselect(:multiple="true", :options="bundleOptions", v-model="filters.store_bundle_id",
                    track-by="name", label="name", deselect-label="", select-label="", @search-change="debounceBundleSearch",
                    :loading="bundleOptionsLoading", :internal-search="false")
                .form-group
                  label Package(s)
                  multiselect(:multiple="true", :options="packageOptions", v-model="filters.store_package_id",
                    track-by="name", label="name", deselect-label="", select-label="", @search-change="debouncePackageSearch",
                    :loading="packageOptionsLoading", :internal-search="false")
              .checboxes
                .form-group.no-label
                  b-form-checkbox(v-model="filters.has_blacklist") Has blacklist
                .form-group.no-label
                  b-form-checkbox(v-model="filters.has_whitelist") Has whitelist
                .form-group.no-label(v-if="USER.special_account_features")
                  b-form-checkbox(v-model="filters.smartlink") Smartlink
                .form-group.no-label(v-if="USER.special_account_features")
                  b-form-checkbox(v-model="filters.show_hidden") Show hidden
                .form-group.no-label
                  b-form-checkbox(v-model="filters.show_direct_only") Direct Only
                .form-group.no-label
                  b-form-checkbox(v-model="filters.show_vta_only") VTA
                .form-group.no-label
                  b-form-checkbox(v-model="filters.show_mine_only") My Offers

            div.advanced-filters-link
              a(href="javascript:void(0);", @click="showAdvancedFilters = !showAdvancedFilters") {{ showAdvancedFilters ? 'Hide' : 'Show' }} advanced filters
                i.la(:class="{'la-angle-down':!showAdvancedFilters, 'la-angle-up': showAdvancedFilters}")

          .w-100.mobile.p-2.border-top.position-sticky.bg-white(style="bottom: 0; z-index: 2;")
            b-button-group.submit-filters-button
              b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                b-dropdown-item(@click="loadData(1)")
                  i.la.la-download.mr-1
                  | Export
                b-dropdown-item(href="/app/offers/default-settings")
                  i.la.la-cog.mr-1
                  | Default Settings

          .form-row-end

        paginate(:paginator="paginate", @update-page-size="loadData")

        .ov-table-wrapper
          .tabs.table-tabs
            ul.nav.nav-tabs
              li.nav-item
                a.nav-link(:class="{active:selectedTab==='search'}", @click="selectedTab='search'") Search ({{ paginate.total }})
              li.nav-item
                a.nav-link(:class="{active:selectedTab==='bucket', highlight: bucket.length > 0}", @click="selectedTab='bucket'") Selected
                  span.ml-1.bucket-size ({{bucket.length}})
          table.table.table-bordered.table-offer-list.min-height
            thead
              tr
                th(rowspan="2")
                  div
                    span(v-if="selectedTab==='search'")
                      a(href="javascript:void(0);", @click="selectAll()")
                        i.la.la-check-square-o
                      =' | '
                    span
                      a(href="javascript:void(0);", @click="clearBucket()")
                        i.la.la-square-o
                th(rowspan="2") ID
                th(rowspan="2") &nbsp;
                th(rowspan="2") Advertiser
                th(rowspan="2") Name

                th(:colspan="expandedColumns.settings ? 6 : 2")
                  .d-flex.justify-content-between
                    span Settings
                    a(href="javascript:void(0);", @click="toggleColumn('settings')")
                      i.la(:class="{'la-plus': !expandedColumns.settings, 'la-minus': expandedColumns.settings}")
                th(:colspan="expandedColumns.performance ? 5 : 2")
                  .d-flex.justify-content-between
                    span Performance
                    a(href="javascript:void(0);", @click="toggleColumn('performance')")
                      i.la(:class="{'la-plus': !expandedColumns.performance, 'la-minus': expandedColumns.performance}")
                th(:colspan="expandedColumns.advanced ? (USER.config && USER.config.impressionRate ? 6 : 5) : 2")
                  .d-flex.justify-content-between
                    span Advanced
                    a(href="javascript:void(0);", @click="toggleColumn('advanced')")
                      i.la(:class="{'la-plus': !expandedColumns.advanced, 'la-minus': expandedColumns.advanced}")

                th(rowspan="2") Status
                th(rowspan="2") Comments
                th(rowspan="2")
                  b-dropdown(text="Actions", size="sm", variant="link")
                    b-dropdown-item(href="#", @click="bulkStatistics()")
                      i.la.la-chart-bar.mr-2
                      | View statistics
                    b-dropdown-item(href="#", @click="bulkExport()")
                      i.la.la-download.mr-2
                      | Export
                    b-dropdown-item(href="#", @click="createComplex()")
                      i.la.la-sitemap.mr-2
                      | Create Complex Offer
                    b-dropdown-divider
                    b-dropdown-group(id="dropdown-group-1", header="Bulk Actions")
                      b-dropdown-item(href="#", @click="bulkConnect()")
                        i.la.la-plug.mr-2
                        | Connect
                      b-dropdown-item(href="#", @click="bulkPause()")
                        i.la.la-pause-circle.mr-2
                        | Pause
                      b-dropdown-item(href="#", @click="bulkActivate()")
                        i.la.la-play-circle.mr-2
                        | Activate
                      b-dropdown-item(href="#", @click="bulkCancel()")
                        i.la.la-ban.mr-2
                        | Cancel
                      b-dropdown-item(href="#", @click="bulkModal('daily_click_cap', 'Set Click Cap')")
                        i.la.la-shield-alt.mr-2
                        | Set Click Cap
                      b-dropdown-item(href="#", @click="bulkModal('daily_impression_cap', 'Set Impression Cap')")
                        i.la.la-shield-alt.mr-2
                        | Set Impression Cap
                      b-dropdown-item(href="#", @click="bulkModal('frequency_cap_params', 'Set Frequency Cap')")
                        i.la.la-user-clock.mr-2
                        | Set Frequency Cap
                      b-dropdown-item(@click="bulkModal('impression_rate', 'Set Impression Ratio')", v-if="USER.config && USER.config.impressionRate")
                        i.la.la-percent.mr-2
                        | Set Impression Ratio
                      b-dropdown-item(@click="refreshSubsourcesModal()")
                        i.la.la-refresh.mr-2
                        | Refresh Subsources
                      b-dropdown-item(href="#", @click="bulkAssignSelf()")
                        i.la.la-user-circle.mr-2
                        | Assign to me
                      b-dropdown-item(href="#", @click="bulkUnassign()")
                        i.la.la-user-slash.mr-2
                        | Unassign
              tr
                th Payout
                th Model
                th(v-if="expandedColumns.settings") Geo
                th(v-if="expandedColumns.settings") OS
                th(v-if="expandedColumns.settings") MMP
                th(v-if="expandedColumns.settings") Manager

                th Clicks
                th(v-if="expandedColumns.performance") Impressions
                th Conversions
                th(v-if="expandedColumns.performance") Installs
                th(v-if="expandedColumns.performance") Budget

                th Placements
                th(v-if="expandedColumns.advanced") Auto Src
                th(v-if="expandedColumns.advanced && USER.config && USER.config.impressionRate") Imp Ratio
                th(v-if="expandedColumns.advanced") Redirects
                th(v-if="expandedColumns.advanced") Score
                th Trending

            tbody
              tr(v-if="records.length === 0")
                td(colspan="22") No matching records were found
              tr(v-for="r in (selectedTab==='bucket'?bucket:records)", v-bind:key="r.id", :class="{'row-selected': bucketHash[r.id]}")
                td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
                  i.la.la-lock(v-if="r.reserved", v-b-tooltip.hover.right, title="This offer is reserved for system usage")
                  a(href="javascript:void(0)", @click="toggleBucket(r)", v-if="!r.reserved")
                    i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
                td
                  | {{ r.id }}<br />
                  | [{{ r.external_id }}]<br />
                  offer-paused-reason(:offer="r")
                  //span(v-if="r.enable_smartlink", v-b-tooltip.hover.bottom title="Smartlink")
                    i.fa.fa-play-circle
                  span(v-if="r.default_enable_api", v-b-tooltip.hover.bottom, title="Enable in API")
                    i.fa.fa-code
                  //toggle-button(v-if="!r.reserved", v-model="r._enable_smartlink", :font-size="10", @change="updateField(r, 'enable_smartlink')", :labels="{checked: 'SL', unchecked: 'SL'}", :margin="1", :height="16", :width="40")
                td
                  div.app-icon-container
                    a(href="javascript:void(0)", @click="toggleComplexFilter(r)")
                      span.complex-badge(v-if="r.type === 'complex'", :class="{'active': filters.complex_id === r.id}") {{ r.connected_offers }}
                    img(v-if="r.store_icon", :src="r.store_icon")
                td
                  entity(:id="r.advertiser_id", :name="r.advertiser_name", type="advertiser")
                td.edit-inline
                  quick-edit(field="name", :r="r", @on-save="updateField", :wrapped="true")
                  div.s2s-badge(v-if="r.s2s") S2S
                  div.category {{ r.category }}
                  div.created_at {{ r.created_at }}

                td
                  div.payout-wrapper
                    b-form-checkbox(v-if="!r._edit_payout", :disabled="!!r.reserved", v-model="r._auto_payout", v-b-tooltip.hover, title="Auto Payout", @input="updateAutoPayout(r)")
                    span.auto-payout-value(v-if="r._auto_payout") {{ r.payout }} {{ r.currency }}
                    quick-edit(v-if="!r._auto_payout", field="payout", :r="r", @on-save="updateField", :wrapped="false")
                  div.placement-payout-wrapper
                    quick-edit(field="default_placement_payout", :r="r", @on-save="updateField", :wrapped="false")
                td.edit-inline
                  quick-edit-select(field="payout_type", :r="r", @on-save="updateField", :wrapped="true", :options="payoutModelOptions")
                  //{{ r.payout_type }}
                td.edit-inline(v-if="expandedColumns.settings")
                  quick-edit(field="country", :r="r", @on-save="updateField", :wrapped="true", type="textarea")
                  // span(v-if="r.country", style="display: inline-block; max-width: 300px; word-break: break-all; font-size: 10px") {{ r.country.join(', ') }}
                td.edit-inline(v-if="expandedColumns.settings")
                  quick-edit(field="platform", :r="r", @on-save="updateField", :wrapped="true")
                td.mmp(v-if="expandedColumns.settings")
                  mmp-icon(:mmp="r.mmp")
                  div.direct-badge(v-if="r.is_direct_link", style="color: #eeaa22; font-size: 18px;")
                    i.la.la-star
                  div.vta-badge(v-if="r.is_vta", style="color: #eeaa22;") VTA
                td.manager(v-if="expandedColumns.settings")
                  b-avatar(v-if="r.manager_id", :variant="r.manager_variant", :text="r.manager_abv", v-b-tooltip.hover.right, :title="r.manager_name")


                td.no-wrap.edit-inline(style="width: 90px")
                  .quickedit-buttons(v-if="!r.reserved")
                    a.edit-button(href="javascript:void(0)", @click="editOfferCaps(r)")
                      i.la.la-pencil
                  div(v-if="!!r.tracking_url || r.type === 'complex'") {{ r.current_click_count | shortNumber }} / {{ r.daily_click_cap | shortNumber }}
                td.no-wrap.edit-inline(v-if="expandedColumns.performance", style="width: 90px")
                  .quickedit-buttons(v-if="!r.reserved")
                    a.edit-button(href="javascript:void(0)", @click="editOfferCaps(r)")
                      i.la.la-pencil
                  div(v-if="!!r.impression_url || r.type === 'complex'") {{ r.current_impression_count | shortNumber }} / {{ r.daily_impression_cap | shortNumber }}
                td.no-wrap.edit-inline(style="width: 90px")
                  .quickedit-buttons(v-if="!r.reserved")
                    a.edit-button(href="javascript:void(0)", @click="editOfferCaps(r)")
                      i.la.la-pencil
                  div {{ r.current_conversion_count | shortNumber }} / {{ r.daily_conversion_cap | shortNumber }}
                td.no-wrap.edit-inline(v-if="expandedColumns.performance", style="width: 90px")
                  .quickedit-buttons(v-if="!r.reserved")
                    a.edit-button(href="javascript:void(0)", @click="editOfferCaps(r)")
                      i.la.la-pencil
                  div {{ r.current_daily_install_count | shortNumber }} / {{ r.daily_install_cap | shortNumber }}
                td.col-budget(v-if="expandedColumns.performance")
                  .budget-bar(v-if="r.daily_budget")
                    span D
                    b-progress(:value="r.used_daily_budget", :max="r.daily_budget", show-progress, animated, variant="primary", v-b-tooltip.hover.right, :title="'Daily '+r.used_daily_budget + ' / ' + r.daily_budget")
                  .budget-bar(v-if="r.monthly_budget")
                    span M
                    b-progress(:value="r.used_monthly_budget", :max="r.monthly_budget", show-progress, animated, variant="info", v-b-tooltip.hover.right, :title="'Monthly '+r.used_monthly_budget + ' / ' + r.monthly_budget")
                //td {{ r.restrict_clicks_convert }}
                // td
                  button.btn-whitelist(type="button", @click="showWLBL(r)", v-if="r.white_list")
                  | &nbsp;
                  button.btn-blacklist(type="button", @click="showWLBL(r)", v-if="r.black_list")


                td
                  router-link(:to="{name:'placement-list', query:{offer_id: r.id}}") {{ r.num_placements_live }} / {{ r.num_placements_total }}
                td.no-wrap(v-if="expandedColumns.advanced")
                  div(v-if="r.subsource_auto_generate && r.subsource_auto_total > 0")
                    span {{ r.subsource_auto_total }}/{{r.subsource_auto_frequency}}h
                    a.ml-1(href="javascript:void(0);", @click="refreshSubsources(r)", v-b-tooltip.hover.right, title="Refresh Subsources")
                      i.la.la-refresh(:class="{'la-spin':r._busy}")
                td.no-wrap(v-if="USER.config && USER.config.impressionRate && expandedColumns.advanced")
                  impression-ratio-display(:value="r.impression_rate")
                  //nb-select.status-selector([placeholder]="r.status", [status]="getStatusClass(r.status)",
                  //style="width: 105px;", size="xsmall", customLabel="''", (selectedChange)="updateStatus(r, $event)")
                    nb-option(*ngFor="let s of statusOptions", [value]="s.v") {{s.t}}
                  //select.form-control([ngClass]="'mat-color-'+r.status", (change)="updateStatus(r,$event.target.value)")
                    option(*ngFor="let s of statusOptions", [value]="s.v", [selected]="s.v == r.status") {{s.t}}
                td(v-if="expandedColumns.advanced")
                  div
                    b-form-checkbox(v-model="r._require_validation", :disabled="!!r.reserved", @change="updateRequireValidation(r)", v-b-tooltip.hover.right, title="Require Link Validation")
                      | {{ r.require_validation ? 'Required' : 'Optional' }}
                  div(v-if="r.offerScan")
                    div.last-scan {{ r.offerScan.last_scan }}
                    span.scan-success(v-if="r.offerScan.status==1") {{ r.offerScan.redirects }} redirects ({{ r.scan_nondirect_jumps }})
                    span.scan-failure(v-if="r.offerScan.status==2") Failed
                    span.scan-error(v-if="r.offerScan.status==3", v-b-tooltip.hover title="r.offerScan.error") API Error
                  a.sm-btn(href="javascript:void(0);", @click="scan(r)", v-b-tooltip.hover.bottom, title="Refresh", v-if="!r.reserved")
                    i.la.la-refresh(:class="{'la-spin':r._busy}")
                  a.sm-btn(href="javascript:void(0);", @click="showOfferScanModal(r)", v-b-tooltip.hover.bottom, title="Details", v-if="!r.reserved")
                    i.la.la-search
                td(v-if="expandedColumns.advanced")
                  div(v-if="USER.special_account_features") {{ r.score }}
                    a(href="javascript:void(0);", @click="calculateScore(r)")
                      i.la.la-refresh(:class="{'la-spin':r._busy}")

                td.trending
                  .badge.badge-pill.badge-success(v-if="r.trending === 'up'") +{{ r.trending_percentage }}%
                  .badge.badge-pill.badge-danger(v-if="r.trending === 'down'") {{ r.trending_percentage }}%
                  .badge.badge-pill.badge-primary(v-if="r.trending === 'new'") New
                td.col-status
                  div.inactive-advertiser(v-if="r.advertiser_status === 0") Adv.Closed
                  b-dd(:text="r.status", :class="'status-'+r.status", v-if="r.advertiser_status", :disabled="!!r.reserved")
                    b-dropdown-item(v-for="o in statusOptions", v-bind:key="o.v", :class="'option-'+o.v", @click="updateStatus(r, o.v)") {{ o.t }}
                td.edit-inline
                  quick-edit(field="comments", :r="r", @on-save="updateField", :wrapped="true", type="textarea")

                td.actions
                  .action-buttons(v-if="!r.reserved")
                    router-link.btn.btn-action-default(:to="{name:r.type==='complex' ? 'complex-edit' : 'offer-edit', params: {id:r.id}}", v-b-tooltip.hover.bottom, title="Edit")
                      i.la.la-pencil
                    //router-link.btn.btn-action-info(v-if="r.type!=='complex'", :to="{name:'offer-add', query:{copy_offer_id:r.id}}", v-b-tooltip.hover.bottom, title="Duplicate Offer")
                      i.la.la-copy
                    //router-link.btn.btn-action-info(:to="{name:'placement-add', query:{offer_id:r.id}}", v-b-tooltip.hover.bottom, title="Create placement")
                      i.la.la-plus
                    //| &nbsp;
                    //button.btn.btn-action-default(@click="showOriginal(r)", v-b-tooltip.hover.bottom, title="Show Original", v-if="r.external_offer_id")
                      i.la.la-code
                    router-link.btn.btn-action-info(:to="{name:'reports', query: r.type==='complex' ? {complex_offer_id:r.id} : {offer_id:r.id}}", v-b-tooltip.hover.bottom, title="Statistics")
                      i.la.la-bar-chart
                    b-dropdown(v-if="r.external_offer_id || r.type!=='complex' || (r.subsource_auto_generate && r.subsource_auto_frequency && r.subsource_auto_total > 0)", size="sm", variant="link", v-b-tooltip.hover.bottom, no-caret)
                      template(#button-content)
                        i.la.la-ellipsis-h
                      b-dropdown-item(v-if="r.type!=='complex'", :to="{name:'offer-add', query:{copy_offer_id:r.id}}")
                        i.la.la-copy.mr-2
                        | Duplicate Offer
                      b-dropdown-item(v-if="r.external_offer_id", @click="showOriginal(r)")
                        i.la.la-code.mr-2
                        | Show Original
                      //b-dropdown-item(v-if="r.subsource_auto_generate && r.subsource_auto_total > 0", @click="refreshSubsources(r)")
                        i.la.la-refresh.mr-2
                        | Refresh Subsources


        //include offer-list-row
        //tbody
          tr(v-if="!records.length")
            td(colspan="16") No matching records were found
          tr(v-for="r in records", v-bind:key="r.id")
            td {{ r.id }}
            td
              toggle-button(v-model="r._status", :font-size="12", @change="updateField(r, 'status')")
            td.edit-inline
              quick-edit(field="name", :r="r", @on-save="updateField", :wrapped="true")
            td
              toggle-button(v-model="r._fetch_api", :font-size="12", @change="updateField(r, 'fetch_api')")
            td.has-actions {{ r.last_fetch_api }}
              a.cell-action(href="javascript:void(0)", @click="fetchApi(r)", v-b-tooltip.hover, title="Fetch now")
                i.la.la-refresh(:class="{'la-spin':r._busy_fetch_api}")
            td {{ r.api_num_offers }}
            td.has-actions {{ r.pub_key }}
              a.cell-action(href="javascript:void(0)", @click="generateSecret(r)", v-b-tooltip.hover, title="Generate Secret")
                i.la.la-refresh
            td {{ r.created_at }}
            td.actions
              router-link.btn.btn-sm.btn-secondary(:to="{name:'advertiser-edit', params: {id:r.id}}")
                i.la.la-pencil
              | &nbsp;
              a.btn.btn-sm.btn-secondary(href="")
                i.la.la-trash

        paginate(:paginator="paginate", @update-page-size="loadData")

        b-modal(id="bulk-modal", :title="bulkModalTitle", @ok="bulkModalConfirm()", size="lg")
          form
            .form-group(v-if="bulkModalField==='payout'")
              label Format
              select.form-control(v-model="bulkModalFormat")
                option(value="fixed") Fixed
                option(value="percent") Percent
            .form-group(v-if="bulkModalField==='risk_management'")
              label Action
              select.form-control(v-model="bulkModalFormat")
                option(value="value") Set value
                option(value="reset") Reset
            .form-group(v-if="bulkModalField !== 'impression_rate' && bulkModalField !== 'frequency_cap_params'")
              label Value
              input.form-control(v-model="bulkModalValue")

            impression-ratio(v-model="bulkModalValue", v-if="bulkModalField === 'impression_rate'")

            frequency-cap(v-model="bulkModalValue", v-if="bulkModalField === 'frequency_cap_params'")



</template>
<style lang="scss">
.col-budget {
  .budget-bar {
    display: flex;

    .progress {
      margin-left: 5px;
      width: 100%;
      min-width: 80px;
    }
  }
}

.inactive-advertiser {
  background: #8d95ab;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 18px;
  border-radius: 4px;
  padding: 3px 6px;
  color: #fff;
}

.btn-whitelist {
  border: 1px solid #eee;
  width: 24px;
  height: 24px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
}

.btn-blacklist {
  border: 1px solid #eee;
  width: 24px;
  height: 24px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #000;
  cursor: pointer;
}

.last-scan {
  color: #aaa;
}

.scan-success {
  color: #0e6b0e;
}

.scan-failure {
  color: #cfb52c;
}

.scan-error {
  color: #852721;
}

.trending {
  .badge {
    font-size: 90%;
  }
}

.label-badge {
  line-height: 24px;
  text-align: center;
  color: #fff;
  background: #00aaaa;
  font-size: 11px;
  border-radius: 25%;
  margin-right: 5px;
  padding: 2px 5px;
}

.app-icon-container {
  width: 50px;
  height: 50px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background: #ccc;
  border-radius: 10px;
  position: relative;

  img {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }

  .complex-badge {
    position: absolute;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    background: #ff5252;
    font-size: 14px;
    border-radius: 100%;
    box-shadow:
      0 0 4px rgba(0, 0, 0, 0.3),
      inset 0 0 4px rgba(0, 0, 0, 0.1);
    top: -4px;
    left: -4px;
    font-weight: bold;
    text-decoration: none;

    &.active {
      background: #ec9602;
    }
  }
}

.category {
  position: absolute;
  bottom: 2px;
  left: 2px;
  font-size: 10px;
  color: #aaa;
}

.created_at {
  position: absolute;
  bottom: 2px;
  right: 2px;
  font-size: 10px;
  color: #aaa;
}

.no-wrap {
  white-space: nowrap;
}

.limit-box {
  max-width: 300px;
  word-break: break-all;
}

.btn-whitelist {
  border: 1px solid #eee;
  width: 24px;
  height: 24px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
}

.btn-blacklist {
  border: 1px solid #eee;
  width: 24px;
  height: 24px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #000;
  cursor: pointer;
}

.s2s-badge {
  background: #e8e7e3;
  border-radius: 5px;
  font-size: 10px;
  position: absolute;
  bottom: 4px;
  padding: 0 4px;
}
</style>
<script>
import Vue from 'vue';
import { debounce } from 'vue-debounce';
import OfferScanModal from '../modals/OfferScanModal';
import OfferWLBLModal from '../modals/OfferWLBLModal';
import QuickConnectOffersModal from '../modals/QuickConnectOffersModal';
import CodeViewModal from '../modals/CodeViewModal';
import OfferExportModal from '../modals/OfferExportModal';
import QuickEditOfferModal from '../modals/QuickEditOfferModal';
import RefreshSubsourcesModal from '../modals/RefreshSubsourcesModal';

export default {
  name: 'OfferList',
  async beforeRouteEnter(to, from, next) {
    let complexId = +to.query.complex_id;
    let advertiserId = +to.query.advertiser_id;

    let advertiserOptions = await Vue.ovData.advertiser.getOptions();
    let mmpAccountOptions = await Vue.ovData.mmpAccount.getOptions(true);
    let accountManagerOptions = await Vue.ovData.user.getOptions(true, ['admin', 'am']);
    // let labelOptions = await Vue.ovData.label.getOptions();
    next((vm) => {
      vm.advertiserOptions = advertiserOptions;
      vm.accountManagerOptions = accountManagerOptions;
      vm.mmpAccountOptions = mmpAccountOptions;
      // vm.labelOptions = labelOptions;
      if (advertiserId) {
        vm.filters.advertiser_id = advertiserId;
      }
      if (complexId) {
        vm.filters.complex_id = complexId;
      }
      vm.loadData();
      return vm;
    });
  },
  components: {
    OfferScanModal,
    OfferWLBLModal,
    QuickConnectOffersModal,
    CodeViewModal,
    OfferExportModal,
    QuickEditOfferModal,
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    advertiserOptionsLive() {
      return this.advertiserOptions.filter((a) => a.status === 1);
    },
  },
  data() {
    const defaultFilters = {
      mmp: 'all',
      offer_ids: '',
      advertiser_id: [],
      am_id: [],
      exclude_advertiser_id: [],
      store_bundle_id: [],
      store_package_id: [],
      keyword: '',
      app: null,
      has_blacklist: false,
      has_whitelist: false,
      show_hidden: false,
      show_direct_only: false,
      show_vta_only: false,
      show_mine_only: false,
      smartlink: false,
      platform: 'all',
      status: 'live',
      group_name: '',
      validation_status: 'all',
      countries: '',
      payout_model: 'all',
      max_redirects: 0,
      offer_type: 'all',
      complex_id: 0,
    };
    return {
      areFiltersOpened: false,
      expandedColumns: {
        settings: false,
        performance: false,
        advanced: false,
      },
      busy: false,

      bulkModalField: null,
      bulkModalTitle: null,
      bulkModalValue: 0,
      bulkModalFormat: 'fixed',

      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      showAdvancedFilters: false,
      selectedTab: 'search',
      defaultFilters,
      filters: { ...defaultFilters },
      lastQueryFilters: {},
      add_advertiser_id: 0,
      bucket: [],
      bucketHash: {},
      records: [],
      mmpOptions: [
        { v: 'appsflyer', t: 'AppsFlyer' },
        { v: 'adjust', t: 'Adjust' },
        { v: 'kochava', t: 'Kochava' },
        { v: 'branch', t: 'Branch' },
        { v: 'singular', t: 'Singular' },
        { v: 'tenjin', t: 'Tenjin' },
      ],
      statusOptions: [
        { v: 'all', t: 'All' },
        { v: 'draft', t: 'Draft' },
        { v: 'live', t: 'Live' },
        { v: 'paused', t: 'Paused' },
        { v: 'cancelled', t: 'Cancelled' },
        // {v: 'ended', t: 'Ended'}
      ],
      validationStatusOptions: [
        { v: 'all', t: 'All' },
        { v: 'optional', t: 'Optional' },
        { v: 'all_open', t: 'All open' },
        { v: 'valid', t: 'Valid' },
        { v: 'api_error', t: 'API Error' },
        { v: 'required_valid', t: 'Required (valid)' },
        { v: 'required_invalid', t: 'Required (invalid)' },
        { v: 'required_new', t: 'Required (new)' },
      ],
      platformOptions: [
        { v: 'all', t: 'All' },
        { v: 'android', t: 'Android' },
        { v: 'ios', t: 'iOS' },
        { v: 'mobile_web', t: 'MobileWeb' },
        { v: 'desktop', t: 'Desktop' },
        { v: 'empty', t: 'Empty' },
      ],
      frequencyCapTimeframeOptions: [
        { v: 24, t: '24 Hours' },
        { v: 12, t: '12 Hours' },
        { v: 6, t: '6 Hours' },
        { v: 3, t: '3 Hours' },
        { v: 2, t: '2 Hours' },
        { v: 1, t: '1 Hour' },
      ],
      payoutModelOptions: Vue.ovData.general.payoutTypeOptions.map((o) => {
        return { v: o.v, t: o.v };
      }),
      advertiserOptionsArchive: false,
      advertiserOptions: [],
      mmpAccountOptions: [],
      accountManagerOptions: [],
      bundleOptionsLoading: false,
      bundleOptions: [],
      packageOptionsLoading: false,
      packageOptions: [],
      sortBy: {
        field: 'id',
        direction: 'desc',
        sort: null,
      },
      // labelOptions: [],
      // newLabel: ''
      variantOptions: [
        'secondary',
        'primary',
        'dark',
        'light',
        'success',
        'danger',
        'warning',
        'info',
      ],
    };
  },
  methods: {
    resetFilters() {
      this.paginate.page = 1;
      this.filters = {
        offer_ids: '',
        advertiser_id: [],
        exclude_advertiser_id: [],
        store_bundle_id: [],
        store_package_id: [],
        keyword: '',
        mmp_account_id: '',
        app: null,
        has_blacklist: false,
        has_whitelist: false,
        show_hidden: false,
        smartlink: false,
        platform: 'all',
        status: 'live',
        validation_status: 'all',
        countries: '',
        payout_model: 'all',
        max_redirects: 0,
        offer_type: 'all',
        complex_id: 0,
        am_id: [],
      };
    },
    doSort() {
      this.loadData();
    },
    toggleColumn: function (group) {
      Vue.set(this.expandedColumns, group, !this.expandedColumns[group]);
    },
    bulkModal(field, title) {
      let selectedIds = this.bucket.map((r) => r.id);
      if (selectedIds.length === 0) {
        return this.$ovNotify.error('No items selected');
      }
      this.bulkModalField = field;
      this.bulkModalTitle = title;
      this.bulkModalValue = 0;
      if (field === 'risk_management') {
        this.bulkModalFormat = 'fixed';
      } else if (field === 'payout') {
        this.bulkModalFormat = 'value';
      } else if (field === 'frequency_cap_params') {
        let emptyObj = { cap: 0, timeframe: 1, fingerprint: 'ip_ua' };
        this.bulkModalValue = {
          mode: 'combined',
          impression: Object.assign({}, emptyObj),
          click: Object.assign({}, emptyObj),
          combined: Object.assign({}, emptyObj),
        };
      }
      this.$bvModal.show('bulk-modal');
    },

    async bulkModalConfirm() {
      let selectedIds = this.bucket.map((r) => r.id);
      let data = {
        offer_id: selectedIds,
        field: this.bulkModalField,
        value: this.bulkModalValue,
        format: this.bulkModalFormat,
      };
      try {
        this.busy = true;
        let resp = await this.$ovReq.post('offer/bulkSet', data);
        this.bucket.forEach((r) => {
          for (let f in resp.field) {
            let v = resp.field[f];
            Vue.set(r, f, v);
            Vue.set(r, '_' + f, v);
          }
        });
        this.loadData();
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
        this.loadData();
      });
    },
    async showOriginal(r) {
      try {
        let originalCode = await this.$ovReq.get('offer/getOriginal/' + r.id);
        this.busy = true;
        this.busy = false;
        this.$modal.show(
          CodeViewModal,
          {
            code: JSON.stringify(originalCode.original),
          },
          {
            height: '90%',
          },
        );
      } catch (e) {
        this.busy = false;
      }
    },

    async searchBundle(query) {
      this.bundleOptionsLoading = true;
      try {
        this.bundleOptions = await Vue.ovData.offer.getBundleOptions({ keyword: query });
      } catch (e) {
        console.error(e);
      }
      this.bundleOptionsLoading = false;
    },

    async searchPackage(query) {
      this.packageOptionsLoading = true;
      try {
        this.packageOptions = await Vue.ovData.offer.getPackageOptions({ keyword: query });
      } catch (e) {
        console.error(e);
      }
      this.packageOptionsLoading = false;
    },

    refreshSubsourcesModal() {
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });
      this.$modal.show(
        RefreshSubsourcesModal,
        {
          offer_ids: offerIds,
        },
        {
          height: '90%',
          width: '90%',
          minWidth: 500,
        },
        {
          'before-close': (/*event*/) => {
            // this.$emit('input', selectedOffers);
          },
        },
      );
    },

    toggleComplexFilter(o) {
      if (!this.filters.complex_id) {
        this.resetFilters();
        this.filters.complex_id = o.id;
        this.filters.offer_type = 'all';

        if (!this.$route.query.complex_id) {
          this.$router.push({ name: 'offer-list', query: { complex_id: o.id } });
        }
      } else {
        this.resetFilters();
        this.filters.offer_type = 'all';
        if (this.$route.query.complex_id) {
          this.$router.push({ name: 'offer-list' });
        }
      }

      this.loadData();
    },

    createComplex() {
      if (this.bucket.length === 0) {
        return this.$ovNotify.error('You must select offers');
      }
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });
      this.$router.push({ name: 'complex-add', query: { offer_ids: offerIds.join(',') } });
    },

    async bulkAssignSelf() {
      if (this.bucket.length === 0) {
        return this.$ovNotify.error('You must select offers');
      }
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });
      try {
        let data = {
          offer_ids: offerIds,
        };
        await this.$ovReq.post('offer/bulkAssignSelf', data);
        this.$ovNotify.success('Offers have been assigned successfully');
        this.loadData();
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },

    async bulkUnassign() {
      if (this.bucket.length === 0) {
        return this.$ovNotify.error('You must select offers');
      }
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });
      try {
        let data = {
          offer_ids: offerIds,
        };
        await this.$ovReq.post('offer/bulkUnassign', data);
        this.$ovNotify.success('Offers have been unassigned successfully');
        this.loadData();
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },

    bulkConnect() {
      if (this.bucket.length === 0) {
        return this.$ovNotify.error('You must select offers');
      }
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });
      this.$modal.show(
        QuickConnectOffersModal,
        {
          offerIds: offerIds,
        },
        {
          height: '90%',
        },
      );
    },
    async bulkPause() {
      if (this.bucket.length === 0) {
        return this.$ovNotify.error('You must select offers');
      }
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });
      try {
        let data = {
          offer_ids: offerIds,
        };
        await this.$ovReq.post('offer/bulkPause', data);
        this.$ovNotify.success('Offers have been paused successfully');
        this.loadData();
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },
    async bulkActivate() {
      if (this.bucket.length === 0) {
        return this.$ovNotify.error('You must select offers');
      }
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });
      try {
        let data = {
          offer_ids: offerIds,
        };
        await this.$ovReq.post('offer/bulkActivate', data);
        this.$ovNotify.success('Offers have been paused successfully');
        this.loadData();
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },
    async bulkCancel() {
      if (this.bucket.length === 0) {
        return this.$ovNotify.error('You must select offers');
      }
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });
      try {
        let data = {
          offer_ids: offerIds,
        };
        await this.$ovReq.post('offer/bulkCancel', data);
        this.$ovNotify.success('Offers have been cancelled successfully');
        this.loadData();
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },
    bulkStatistics() {
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });
      if (!offerIds.length) {
        return;
      }
      this.$router.push({ name: 'reports', query: { offer_id: offerIds.join(',') } });
    },
    bulkExport() {
      let offerIds = this.bucket.map((o) => {
        return o.id;
      });

      this.$modal.show(
        OfferExportModal,
        {
          offerIds: offerIds,
        },
        {
          height: '90%',
        },
      );
    },
    // async bulkSetLabel(labelId){
    // 	if (this.bucket.length === 0) {
    // 		return this.$ovNotify.error('You must select offers');
    // 	}
    // 	let offerIds = this.bucket.map(o => {
    // 		return o.id;
    // 	});
    // 	let data = { offer_ids: offerIds };
    // 	if (labelId && typeof labelId === 'number') {
    // 		data.label_id = labelId;
    // 	} else {
    // 		if (!this.newLabel) {
    // 			return this.$ovNotify.error('No label selected');
    // 		}
    // 		data.new_label = this.newLabel;
    // 	}
    // 	try {
    // 		let resp = await this.$ovReq.post('label/setOffers', data);
    // 		if (resp.label) {
    // 			this.labelOptions.push({
    // 				v: resp.label.id,
    // 				t: resp.label.name,
    // 			});
    // 		}
    // 		this.$ovNotify.success('Offers labels have been updated');
    // 		this.newLabel = '';
    // 		await this.loadData();
    // 	} catch (e) {
    // 		this.$ovNotify.error(e);
    // 	}
    // },
    // async bulkClearLabels(){
    // 	if (this.bucket.length === 0) {
    // 		return this.$ovNotify.error('You must select offers');
    // 	}
    // 	let offerIds = this.bucket.map(o => {
    // 		return o.id;
    // 	});
    // 	let data = {
    // 		offer_ids: offerIds
    // 	};
    // 	try {
    // 		await this.$ovReq.post('label/clearOffers', data);
    // 		this.$ovNotify.success('Offers labels have been updated');
    // 		await this.loadData();
    // 	} catch (e) {
    // 		this.$ovNotify.error(e);
    // 	}
    // },
    clearBucket() {
      this.bucket = [];
      this.bucketHash = {};
    },
    selectAll() {
      this.records.forEach((r) => {
        if (!r.reserved && !this.bucketHash[r.id]) {
          Vue.set(this.bucketHash, r.id, true);
          this.bucket.push(r);
        }
      });
    },

    showOfferScanModal(offer) {
      try {
        this.busy = true;
        // let resp = await this.$ovReq.get('advertiser-feed/get/' + r.id + '?extend=1');
        // let advertiserFeed = resp.entity;
        this.busy = false;
        this.$modal.show(
          OfferScanModal,
          {
            offer,
          },
          {
            height: '90%',
          },
        );
      } catch (e) {
        this.$ovNotify.error(e);
        this.busy = false;
      }
    },

    showWLBL(offer) {
      this.$modal.show(
        OfferWLBLModal,
        {
          offer,
        },
        {
          height: '90%',
        },
      );
    },

    addOffer(advertiserId) {
      this.$router.push({ name: 'offer-add', query: { advertiser_id: advertiserId } });
    },
    updateStatus(r, v) {
      r._status = v;
      this.updateField(r, 'status');
    },
    async updateField(r, field) {
      let data = {
        id: r.id,
        key: field,
        field: field,
        val: r['_' + field],
        value: r['_' + field],
      };
      if (Vue.ovData.offer._booleans.indexOf(field) > -1) {
        data.val = data.val ? 1 : 0;
      }
      try {
        let resp = await this.$ovReq.post('offer/updateField', data);
        r['_edit_' + field] = false;
        r[field] = resp.entity[field];
        this.$ovNotify.success('Record has been updated');
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },

    quickCancel(r, field) {
      r['_edit_' + field] = false;
    },
    toggleEdit(r, field) {
      r['_edit_' + field] = true;
      r['_' + field] = r[field];
    },

    updateAutoPayout(r) {
      this.updateField(r, 'auto_payout');
    },

    updateRequireValidation(r) {
      setTimeout(() => {
        this.updateField(r, 'require_validation');
      });
    },

    toggleBucket(r) {
      if (r.reserved) return;
      if (this.bucketHash[r.id]) {
        // delete this.bucketHash[r.id];
        Vue.set(this.bucketHash, r.id, false);
        for (let i = 0; i < this.bucket.length; i++) {
          if (this.bucket[i].id === r.id) {
            this.bucket.splice(i, 1);
            return;
          }
        }
      } else {
        // this.bucketHash[r.id] = true;
        Vue.set(this.bucketHash, r.id, true);
        this.bucket.push(r);
      }
    },

    async calculateScore(r) {
      if (r._busy) {
        return;
      }
      Vue.set(r, '_busy', true);
      try {
        let resp = await this.$ovReq.post('offer/calculateScore', { id: r.id });
        r.score = resp.score;
      } catch (e) {
        console.error(e);
      }
      Vue.set(r, '_busy', false);
    },

    async refreshSubsources(r) {
      if (r._busy) {
        return;
      }
      Vue.set(r, '_busy', true);
      try {
        await this.$ovReq.post('offer/refreshSubsources', { id: r.id });
      } catch (e) {
        console.error(e);
      }
      Vue.set(r, '_busy', false);
    },

    async scan(r) {
      if (r._busy) {
        return;
      }
      const reqParams = {
        offer_id: r.id,
        // external_offer_id: this.externalOffer ? this.externalOffer.id : null,
        level: 0,
        pixel: 0,
        scanner: 'affilitest',
      };
      r._busy = true;
      try {
        let resp = await this.$ovReq.post('offer/scan', reqParams);
        r.offerScan = resp.entity;
      } catch (e) {
        console.error(e);
      }
      r._busy = false;
    },

    filtersChangeDetected() {
      for (let f in this.filters) {
        if (
          !this.lastQueryFilters.hasOwnProperty(f) ||
          this.lastQueryFilters[f] !== this.filters[f]
        ) {
          return true;
        }
      }
      return false;
    },

    async loadData(doExport) {
      if (this.busy) {
        return;
      }
      if (this.filtersChangeDetected()) {
        this.paginate.page = 1;
        this.lastQueryFilters = Object.assign({}, this.filters);
      }
      this.busy = true;
      let params = {
        export: doExport ? doExport : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
        group_name: this.filters.group_name,
        advertiser_id: this.filters.advertiser_id.map((r) => r.v).join(','),
        exclude_advertiser_id: this.filters.exclude_advertiser_id.map((r) => r.v).join(','),
        store_bundle_id: this.filters.store_bundle_id.map((r) => r.name).join(','),
        store_package_id: this.filters.store_package_id.map((r) => r.name).join(','),
        has_blacklist: this.filters.has_blacklist ? 1 : 0,
        has_whitelist: this.filters.has_whitelist ? 1 : 0,
        show_hidden: this.filters.show_hidden ? 1 : 0,
        smartlink: this.filters.smartlink ? 1 : 0,
        countries: this.filters.countries,
        status: this.filters.status,
        validation_status: this.filters.validation_status,
        platform: this.filters.platform,
        payout_model: this.filters.payout_model,
        sort_col: this.sortBy.field,
        sort_dir: this.sortBy.direction,
        max_redirects: this.filters.max_redirects,
        offer_ids: this.filters.offer_ids,
        offer_type: this.filters.offer_type,
        complex_id: this.filters.complex_id,
        mmp: this.filters.mmp,
        mmp_account_id: this.filters.mmp_account_id,
        user_id:
          this.filters.show_mine_only && this.USER
            ? this.USER.id
            : this.filters.am_id.map((r) => r.v).join(','),
        show_direct_only: this.filters.show_direct_only ? 1 : 0,
      };
      try {
        let resp = await this.$ovReq.get('offer/getList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.records.forEach((r) => {
            r.country = r.country.join(', ');
            if (r.manager_id) {
              r.manager_abv = this.getAbbreviation(r.manager_name);
              r.manager_variant = this.variantOptions[r.manager_id % this.variantOptions.length];
            }

            Vue.set(r, '_busy', false);
            Vue.set(r, '_edit_name', false);
            Vue.set(r, '_name', r.name);

            Vue.set(r, '_enable_smartlink', r.enable_smartlink);

            Vue.set(r, '_edit_country', false);
            Vue.set(r, '_country', r.country);

            Vue.set(r, '_edit_platform', false);
            Vue.set(r, '_platform', r.name);

            Vue.set(r, '_edit_payout', false);
            Vue.set(r, '_payout', r.payout);
            Vue.set(r, '_edit_default_placement_payout', false);
            Vue.set(r, '_default_placement_payout', r.name);
            Vue.set(r, '_auto_payout', r.auto_payout);
            Vue.set(r, '_require_validation', r.require_validation);

            Vue.set(r, '_status', r.status);
          });
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
    getAbbreviation(name, limit = 2) {
      let nameArr = name.split(' ');
      return nameArr
        .splice(0, limit)
        .map((n) => n.charAt(0))
        .join('');
    },
    editOfferCaps(r) {
      this.$modal.show(
        QuickEditOfferModal,
        {
          id: r.id,
          caps_only: true,
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': async (/*event*/) => {
            await this.loadData();
          },
        },
      );
    },
  },
  created() {
    this.debounceBundleSearch = debounce(this.searchBundle.bind(this), 300);
    this.debouncePackageSearch = debounce(this.searchPackage.bind(this), 300);
  },
};
</script>
