<template lang="pug">
	div.default-modal
		.modal-header
			.modal-title Connect Publishers
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				.row
					.col-sm-4(v-for="p in publishers", v-if="showInactive || p.status")
						.form-text(:class="{'text-muted':!p.status}")
							b-form-checkbox(v-model="p._selected") {{ p.name }}
		.modal-footer
			b-form-checkbox.pull-left(v-model="showInactive", style="margin-right: auto;") Show inactive
			button.btn.btn-primary.pull-right(type="text", @click="submit()")
				| Submit
				i.la(:class="{'la-spinner la-spin':busy, 'la-chevron-right': !busy}")
</template>
<script>
export default {
  name: 'QuickConnectOffersModal',
  props: ['offerIds'],
  data() {
    return {
      busy: false,
      publishers: [],
      showInactive: false,
    };
  },
  methods: {
    toggleInactive() {
      this.showInactive = !this.showInctive;
    },
    async loadPublishers() {
      let data = {
        offer_ids: this.offerIds.join(','),
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('offer/getPlacementStatus', { params: data });
        this.publishers = resp.publishers;
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
    async submit() {
      let data = {
        offer_ids: this.offerIds,
        publisher_ids: this.publishers.filter((o) => o._selected).map((o) => o.id),
      };
      if (data.offer_ids.length === 0) {
        return this.$ovNotify.error('Please select publishers');
      }
      this.busy = true;
      try {
        let resp = await this.$ovReq.post('offer/generatePlacements', data);
        this.$ovNotify.success(resp.num_placements + ' placements were generated');
        this.busy = false;
        this.$emit('close');
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    this.loadPublishers();
  },
};
</script>
