<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="true")
		.modal-header
			.modal-title Export Offers
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				form
					p Export <strong>{{ offerIds.length }}</strong> offers
					div(v-for="c in columns")
						b-form-checkbox(v-model="c.selected") {{ c.name }}
					p.text-center
						b-button(variant="primary" type="button", @click="submit()") Export
					p.text-center(v-if="filePath")
						a(:href="filePath", target="_blank") Download file

</template>
<script>
export default {
  name: 'OfferExportModal',
  props: ['offerIds'],
  data() {
    return {
      busy: false,
      filePath: null,
      columns: [
        { name: 'advertiser_id', selected: false },
        { name: 'advertiser_name', selected: false },
        { name: 'payout', selected: false },
        { name: 'daily_click_cap', selected: false },
        { name: 'daily_conversion_cap', selected: false },
        { name: 'status', selected: false },
        { name: 'daily_budget', selected: false },
        { name: 'preview_url', selected: false },
      ],
    };
  },
  methods: {
    async submit() {
      console.log('EXPORT');
      if (this.busy) {
        return;
      }
      this.busy = true;
      let data = {
        offer_ids: this.offerIds,
        columns: this.columns.filter((c) => c.selected).map((c) => c.name),
      };
      try {
        let resp = await this.$ovReq.post('offer/bulkExport', data);
        if (resp.url) {
          this.filePath = resp.url;
          window.open(resp.url);
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
